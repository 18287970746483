<template>
  <div class="d-flex flex-column" style="height: 100%">
    <VOverlay
      :opacity="0.7"
      :value="isLoading"
    >
      <VProgressCircular
        color="#E43A35"
        indeterminate
        :size="80"
        :width="6"
      />
    </VOverlay>
    <div class="ma-7 d-flex align-center">
      <div class="d-flex align-center">
        <span class="input-title pr-5">Город</span>
        <CitySelection @city-selected="onCitySelected($event)" />
      </div>

    </div>
    <div class="ml-7 pr-7">
      <div>
        <p>Количество гексаговнов: {{polygons.length}}</p>
        <p>Среднее количествово ресторанов на гексагон: {{medianRestaurants}}</p>
      </div>
      <div class="circle__wrapper">
        <div class="circle__description">
          <p> Более 50 ресторанов</p>
          <span class="circle circle-100"></span>
        </div>
        <div class="circle__description">
          <p> 40-49 ресторанов</p>
          <span class="circle circle-50"></span>
        </div>
        <div class="circle__description">
          <p> 30-39 ресторанов</p>
          <span class="circle circle-40"></span>
        </div>
        <div class="circle__description">
          <p> 20-29 ресторанов</p>
          <span class="circle circle-30"></span>
        </div>
        <div class="circle__description">
          <p> 10-19 ресторанов</p>
          <span class="circle circle-20"></span>
        </div>
        <div class="circle__description">
          <p> 6-10 ресторанов</p>
          <span class="circle circle-10"></span>
        </div>
        <div class="circle__description">
          <p> Менее 5 ресторанов</p>
          <span class="circle circle-0"></span>
        </div>
      </div>
    </div>
    <div class="polygons__container">
      <VContainer class="pa-0 ma-0 areas-map-wrapper" fluid>
      <div id="leaflet-map" class="polygons__map" />
      </VContainer>
      <div v-if="allRestaurants.length === 0">
        Нет данных
      </div>
      <div  v-else>

       <div class="mb-3 d-flex gap-3">
         <Dropdown
           :items="deliveryTypeStates"
           :selected="selectedTypeStates"
           title="Тип доставки"
           @selected="updateSelected($event)"
         />
         <Dropdown
           :items="deliveryStates"
           :selected="selectedDeliveryStates"
           title="Доставка"
           @selected="updateSelectedDelivery($event)"
         />
         <div>
           <p>Количество: {{sortedRestaurants.length}}</p>
         </div>
       </div>
      <VSimpleTable
        class="elevation-2 width-100-percent"
        height="1000px"
        style="max-width: 100%"
      >
        <thead>
        <tr class="titles-thead">
          <th class="text-left city-column">
            <span>ID</span>
          </th>
          <th class="text-left">
            <span>Название заведения</span>
          </th>
          <th class="text-center delivery-service-column">
            <span>Количество заказов <br>(за месяц)</span>
          </th>
          <th class="text-right">
            <span>Тип доставки</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="res in sortedRestaurants"
          :key="`rests-${res.id}`"
        >
          <td><span>{{ res.id }}</span></td>
          <td><span>{{ res.title }}</span>

          </td>
          <td class="text-center">
            <span>{{ res.orders_count_per_month }}</span>
          </td>
          <td class="text-right">
            <span>{{ res.will_be_delivered_by }}</span>
          </td>
        </tr>
        </tbody>
      </VSimpleTable>
      </div>
    </div>
  </div>
</template>

<script>
import CitySelection from '@/components/CitySelection.vue';
import L from 'leaflet';
import 'leaflet-lasso';
import {
  geoToH3, h3ToGeoBoundary, polyfill,
} from 'h3-js';
import settings from '@/global/global-settings';
import { fetchAllRestaurants, fetchPolygons, fetchRestaurantsByKeys } from '@/api/api';
import Dropdown from '@/components/reusable/DropdownTable.vue';
import restRed from '@/assets/restred.png';
import restGreen from '@/assets/restgreen.png';
import restBlack from '@/assets/restblack.png';

const colors = {
  red: restRed,
  green: restGreen,
  black: restBlack,
};

const deliveryTypeStates = [{ title: 'OD', value: 'OD' }, { title: 'VD', value: 'VD' }];
const deliveryStates = [{ title: 'Доставляет', value: 'arrive' }, { title: 'Не доставляет', value: 'not_arrive' }];
export default {
  name: 'PolygonsMain',
  components: {
    Dropdown,
    CitySelection,
  },
  data() {
    return {
      city: null,
      isLoading: false,
      map: null,
      lassoControl: null,
      currentAreas: {},
      selectedAreas: {},
      circleAreaMenu: {
        enabled: false,
        x: 0,
        y: 0,
      },
      selectPollygon: false,
      markerGroups: {},
      pollygonAreas: [],
      allRestaurants: [],
      page: 1,
      pageRestaurants: 1,
      pageAllRestaurants: 1,
      pageSize: 100,
      polygons: [],
      restaurant: [],
      deliveryTypeStates,
      selectedTypeStates: deliveryTypeStates,
      deliveryStates,
      selectedDeliveryStates: deliveryStates,
      restaurantMarkers: {},
    };
  },
  mounted() {
    this.initMap();
  },
  computed: {
    medianRestaurants() {
      if (this.polygons.length === 0) {
        return 0;
      }
      return Math.round(this.polygons.reduce(
        (acc, res) => acc + res.od_restaurant_ids.length, 0,
      ) / this.polygons.length);
    },
    sortedRestaurants() {
      if (this.selectedTypeStates.length === 1) {
        return this.allRestaurants.filter(
          (res) => res.will_be_delivered_by === this.selectedTypeStates[0].value,
        );
      }
      if (this.selectedDeliveryStates.length === 1) {
        if (this.selectedDeliveryStates[0].value === 'arrive') {
          return this.restaurant;
        }
        if (this.selectedDeliveryStates[0].value === 'not_arrive') {
          const arrivedIds = this.restaurant.map((res) => res.id);
          return this.allRestaurants.filter(
            (res) => !arrivedIds.includes(res.id),
          );
        }
      }
      return this.allRestaurants;
    },
  },
  methods: {
    updateSelected(value) {
      this.selectedTypeStates = value;
    },
    updateSelectedDelivery(value) {
      this.selectedDeliveryStates = value;
    },
    initMap() {
      this.map = L.map('leaflet-map', { zoomControl: false });
      this.markerGroups = L.featureGroup().addTo(this.map);
      L.tileLayer('https://tile2.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1&ts=online_sd').addTo(this.map);
      L.control.zoom({ position: 'topright' }).addTo(this.map);

      this.pollygonalSelection();
      this.lassoControl = L.control.lasso().addTo(this.map);

      this.map.on('click', (event) => this.clickOnMap(event.latlng));
      this.map.on('contextmenu', (event) => this.rightClickOnMap(event.latlng));
      this.map.on('keypress', (event) => {
        if (event.originalEvent.code === 'KeyQ') {
          this.deselectSelectedAreas();
        }
      });
      this.map.on('lasso.finished', (event) => this.lassoSelectLatLongs(event.latLngs));
      this.map.setView([47.926363, 71.336273], 5);
    },
    pollygonalSelection() {
      L.Control.Watermark = L.Control.extend({
        onAdd: () => {
          const span = L.DomUtil.create('span');
          span.classList = ['mdi mdi-pentagon-outline penta'];
          const styles = {
            width: '30px',
            height: '30px',
            backgroundColor: this.$vuetify.theme.defaults.dark.error,
            fontSize: '20px',
            textAlign: 'center',
            color: 'white',
            borderRadius: '5px',
            transition: 'color .5s, border-radius .5s, transform .3s',
          };
          Object.assign(span.style, styles);
          span.setAttribute('title', 'Сбросить выделения (можно просто нажать кнопку: Q)');
          span.addEventListener('click', (e) => {
            e.stopPropagation();
            this.deselectSelectedAreas();
          });
          return span;
        },
      });

      L.control.watermark = (opts) => new L.Control.Watermark(opts);

      L.control.watermark({ position: 'topright' }).addTo(this.map);
    },
    selectArea(areaKey) {
      const isSelectedArea = areaKey in this.selectedAreas;
      if (isSelectedArea) {
        return;
      }

      const isCurrentArea = areaKey in this.currentAreas;
      if (isCurrentArea) {
        this.selectCurrentArea(areaKey);
      } else {
        this.selectNewArea(areaKey);
      }
    },
    selectNewArea(areaKey) {
      const polygon = h3ToGeoBoundary(areaKey);
      this.selectedAreas[areaKey] = L.polygon(polygon, { color: '#ссс', fillOpacity: 0.5 }).addTo(this.map);
    },
    selectCurrentArea(areaKey) {
      this.selectedAreas[areaKey] = this.currentAreas[areaKey];
      this.selectedAreas[areaKey].setStyle({ color: '#ссс', fillOpacity: 0.5 });
    },
    deselectSelectedAreas() {
      Object.keys(this.selectedAreas).forEach(this.deselectArea);
      this.selectedAreas = {};
      this.restaurant = [];
      this.changeIconsAllRestaurants();
    },
    deselectArea(areaKey) {
      const isSelectedArea = areaKey in this.selectedAreas;
      if (!isSelectedArea) {
        return;
      }

      const isCurrentArea = areaKey in this.currentAreas;
      if (isCurrentArea) {
        console.log('isCurrentArea');
        this.deselectCurrentArea(areaKey);
      } else {
        console.log('!isCurrentArea');
        this.deselectNewArea(areaKey);
      }
    },
    deselectNewArea(areaKey) {
      this.selectedAreas[areaKey].remove();
      delete this.selectedAreas[areaKey];
    },
    deselectCurrentArea(areaKey) {
      const layer = this.currentAreas[areaKey];
      layer.setStyle({
        color: layer.options.defaultColor,
        fillOpacity: layer.options.defaultFillOpacity,
      });
      delete this.selectedAreas[areaKey];
    },
    clickOnMap(latLong) {
      const areaKey = geoToH3(latLong.lat, latLong.lng, settings.h3_hexagon_resolution);
      const isSelectedArea = areaKey in this.selectedAreas;
      this.selectArea(areaKey);
      if (!isSelectedArea) {
        this.fetchRestaurantsInfo();
      }
    },
    rightClickOnMap(latLong) {
      const areaKey = geoToH3(latLong.lat, latLong.lng, settings.h3_hexagon_resolution);
      this.deselectArea(areaKey);
      if (Object.keys(this.selectedAreas).length === 0) {
        this.restaurant = [];
        this.changeIconsAllRestaurants();
      } else {
        this.fetchRestaurantsInfo();
      }
    },
    lassoSelectLatLongs(latLongs) {
      const mappedLatLng = latLongs.map((ll) => [ll.lat, ll.lng]);
      polyfill(mappedLatLng, settings.h3_hexagon_resolution).forEach(this.selectArea);
      this.fetchRestaurantsInfo();
    },
    async fetchRestaurantsInfo() {
      this.restaurant = [];
      this.pageRestaurants = 1;
      await this.fetchRestaurantsByAreaKeys();
    },
    async fetchRestaurantsByAreaKeys() {
      const keys = Object.keys(this.selectedAreas);
      await fetchRestaurantsByKeys(this.city.pk, keys, this.pageRestaurants, this.pageSize)
        .then((response) => {
          this.restaurant.push(...response.data);
          if (response.data.length === this.pageSize) {
            this.pageRestaurants += 1;
            this.fetchRestaurantsByAreaKeys();
          } else {
            this.changeIconsAllRestaurants();
          }
        });
    },

    async fetchAllPolygons() {
      await fetchPolygons(this.city.pk, this.page, this.pageSize)
        .then(async (response) => {
          this.polygons.push(...response.data);
          this.drawPolygons(response.data);
          if (response.data.length === this.pageSize) {
            this.page += 1;
            await this.fetchAllPolygons();
          }
        });
    },
    async fetchAllRestaurants() {
      await fetchAllRestaurants(this.city.pk, this.pageAllRestaurants, this.pageSize)
        .then((response) => {
          this.allRestaurants.push(...response.data);
          if (response.data.length === this.pageSize) {
            this.pageAllRestaurants += 1;
            this.fetchAllRestaurants();
          } else {
            this.drawAllRestaurants();
          }
        });
    },
    drawAllRestaurants() {
      this.allRestaurants.forEach((restaurant) => {
        const icon = L.icon({ iconUrl: colors.black, iconSize: [15, 15] });
        this.restaurantMarkers[restaurant.id] = L.marker([restaurant.latitude, restaurant.longitude], { icon }).bindPopup(`
                          <b>ID:</b> ${restaurant.id}<br>
                          <b>Название: </b>${restaurant.title}<br>
                          <b>Тип доставки:</b> ${restaurant.will_be_delivered_by}<br>
                          <b>Адрес:</b> ${restaurant.text}
                      `);
        this.restaurantMarkers[restaurant.id].addTo(this.markerGroups);
      });
    },
    changeIconsAllRestaurants() {
      const deliveryRestIds = this.restaurant.map((res) => res.id);
      Object.keys(this.restaurantMarkers).forEach((id) => {
        if (deliveryRestIds.length === 0) {
          this.restaurantMarkers[id].setIcon(
            L.icon({ iconUrl: colors.black, iconSize: [15, 15] }),
          );
        } else if (deliveryRestIds.includes(Number(id))) {
          this.restaurantMarkers[id].setIcon(
            L.icon({ iconUrl: colors.green, iconSize: [15, 15] }),
          );
        } else {
          this.restaurantMarkers[id].setIcon(
            L.icon({ iconUrl: colors.red, iconSize: [15, 15] }),
          );
        }
      });
    },
    drawPolygons(polygons) {
      const polygonsFeatureGroup = L.featureGroup();
      polygons.forEach(({ area_key: areaKey, od_restaurant_ids: odRestaurantIds }) => {
        const polygonBoundary = h3ToGeoBoundary(areaKey);
        const restaurantCount = odRestaurantIds.length;

        this.currentAreas[areaKey] = L.polygon(
          polygonBoundary,
          this.getPolygonOptions(restaurantCount),
        );
        polygonsFeatureGroup.addLayer(this.currentAreas[areaKey]);
      });
      polygonsFeatureGroup.addTo(this.map);
      Object.keys(this.currentAreas).forEach((areaKey) => {
        const polygon = this.currentAreas[areaKey];
        polygon.bindTooltip(String(polygon.options.restaurantCount), {
          // permanent: true,
          direction: 'center',
          // sticky: false,
          className: 'tooltip',
        });
      });
    },
    setCenterMapByCity() {
      if (this.city === null) {
        return;
      }

      const cityBorderLatLngs = this.city.border.map(
        (border) => [border.latitude, border.longitude],
      );
      const cityPolygon = L.polygon(cityBorderLatLngs);
      const cityCenter = cityPolygon.getBounds().getCenter();
      this.map.setView(cityCenter, 12);
    },
    getPolygonOptions(sum) {
      if (sum >= 50) {
        return {
          color: '#048848',
          defaultColor: '#048848',
          fillOpacity: 0.5,
          defaultFillOpacity: 0.5,
          weight: 1,
          restaurantCount: sum,
        };
      }
      if (sum >= 40 && sum <= 49) {
        return {
          color: '#06C167',
          defaultColor: '#06C167',
          fillOpacity: 0.5,
          defaultFillOpacity: 0.5,
          weight: 1,
          restaurantCount: sum,
        };
      }
      if (sum >= 30 && sum <= 39) {
        return {
          color: '#FFC043',
          defaultColor: '#FFC043',
          fillOpacity: 0.5,
          defaultFillOpacity: 0.5,
          weight: 1,
          restaurantCount: sum,
        };
      }
      if (sum >= 20 && sum <= 29) {
        return {
          color: '#FFE4A4',
          defaultColor: '#FFE4A4',
          fillOpacity: 0.5,
          defaultFillOpacity: 0.5,
          weight: 1,
          restaurantCount: sum,
        };
      }
      if (sum >= 10 && sum <= 19) {
        return {
          defaultColor: '#C84620',
          color: '#C84620',
          fillOpacity: 0.1,
          defaultFillOpacity: 0.1,
          weight: 1,
          restaurantCount: sum,
        };
      }
      if (sum >= 6 && sum <= 9) {
        return {
          defaultColor: '#E85559',
          color: '#E85559',
          fillOpacity: 0.3,
          defaultFillOpacity: 0.3,
          weight: 1,
          restaurantCount: sum,
        };
      }
      if (sum <= 5) {
        return {
          defaultColor: '#E85559',
          color: '#E85559',
          fillOpacity: 0.5,
          defaultFillOpacity: 0.5,
          weight: 1,
          restaurantCount: sum,
        };
      }
      return {
        defaultColor: '#E85559',
        color: '#E85559',
        fillOpacity: 0.1,
        defaultFillOpacity: 0.1,
        weight: 1,
        restaurantCount: sum,
      };
    },
    onCitySelected(city) {
      this.polygons = [];
      this.allRestaurants = [];

      if (city === null) {
        Object.keys(this.currentAreas).forEach((areaKey) => {
          this.currentAreas[areaKey].remove();
        });
        return;
      }
      this.city = city;
      this.pageAllRestaurants = 1;

      this.page = 1;
      this.setCenterMapByCity();
      this.fetchAllRestaurants();
      this.fetchAllPolygons();
    },
  },
};
</script>

<style lang="scss">
@import "~leaflet/dist/leaflet.css";
@import 'src/assets/scss/page.scss';
@import 'src/assets/scss/mixin.scss';

.tooltip {
  background-color: transparent !important;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
  position: absolute;
  padding: 6px;
  border-radius: 3px;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}
.polygons {
  &__container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 20px;
    height: 100%;
    padding: 20px;
  }
  &__map {
    height: 100%;
    border-radius: 16px;
    width: 100%;
    z-index: 1;
    overflow: hidden;
  }
}

.circle {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  &-100 {
    background-color: rgba(#048848, 0.5);
  }
  &-50 {
    background-color: rgba(#06C167, 0.5);
  }
  &-40 {
    background-color: rgba(#FFC043, 0.5);
  }
  &-30 {
    background-color: rgba(#FFE4A4, 0.5);
  }
  &-20 {
    background-color: rgba(#C84620, 0.1);
  }
  &-10 {
    background-color: rgba(#C84620, 0.3);
  }
  &-0 {
    background-color: rgba(#C84620, 0.5);
  }
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
    max-width: 70%;
  }
  &__description {
    display: flex;
    gap: 10px;
    align-items: center;
    p {
      padding: 0;
      margin: 0;
    }
  }
}
</style>
