<template>
  <VMenu
    v-model="menu"
    :attach="attach || false"
    :close-on-content-click="false"
    content-class="br-4 font-weight-medium white"
    :min-width="isIcon ? width : 180"
    :nudge-left="isIcon ? 244 : 0"
    offset-overflow
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <div>
        <VBtn
          v-if="isIcon"
          v-bind="attrs"
          icon
          small
          v-on="on"
        >
          <VIcon
            :color="menu ? '#E43539' : 'blue-grey darken-3'"
            v-text="'mdi-plus'"
          />
        </VBtn>
        <VBtn
          v-else
          v-bind="attrs"
          class="dropdown text-none elevation-0 pl-3 pr-2 mr-2"
          :class="menu ? 'red-border' : (isActive ? 'reddish' : 'greyish')"
          :disabled="disabled"
          :min-width="minWidth"
          v-on="on"
        >
          <div class="subtitle-2">
            {{ getTitle }}
          </div>
          <VIcon
            class="pt-1"
            size="20"
            v-text="'mdi-chevron-down'"
          />
        </VBtn>
      </div>
    </template>
    <slot name="filters" />
    <VDataTable
      v-model="selectedItems"
      class="light-grey-checkbox"
      :fixed-header="fixedHeader"
      :headers="headers"
      :height="height"
      hide-default-footer
      :hide-default-header="noHeader"
      :item-key="itemKey"
      :items="items"
      :items-per-page="5000"
      :no-data-text="noDataText"
      no-results-text="По запросу ничего не найдено"
      :search="search"
      show-select
      :single-select="singleSelect"
      @input="$emit('selected', $event)"
    >
      <template v-if="searchable" v-slot:top>
        <VTextField
          v-model="search"
          background-color="white"
          class="subtitle-2 font-weight-bold"
          color="#E43539"
          dense
          flat
          hide-details
          :placeholder="searchPlaceholder"
          prepend-inner-icon="mdi-magnify"
          solo
        />
        <VDivider />
      </template>
    </VDataTable>
  </VMenu>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'Все',
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: 'Поиск',
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [String, Number],
      default: null,
    },
    attach: {
      type: String,
    },
    width: {
      type: String,
      default: '280',
    },
    minWidth: {
      type: String,
      default: '',
    },
    max: {
      type: Number,
      default: 3,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    itemKey: {
      type: String,
      default: 'title',
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: 'Нет данных',
    },
    fixedHeader: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['selected'],
  data() {
    return {
      menu: false,
      headers: [{
        text: this.title,
        value: 'title',
        sortable: false,
        class: 'subtitle-2 black--text',
      }],
      selectedItems: [],
      search: '',
    };
  },
  mounted() {
    this.selectedItems = this.selected;
  },
  computed: {
    selectsAll() {
      return this.selectedItems.length === this.items.length;
    },
    isActive() {
      return this.selectedItems.length && !this.selectsAll;
    },
    getTitle() {
      let response = this.title;
      const length = this.selectedItems?.length || 0;
      const andOthers = length > this.max ? ` и еще ${length - this.max}` : '';
      if (length > 0 && length <= this.max && !this.selectsAll) {
        response = this.selectedItems.slice(0, this.max).map((item) => ` ${item.title}`) + andOthers;
      } else if (length > this.max && !this.selectsAll) {
        response = this.selectedItems.slice(0, this.max).map((item) => ` ${item.title}`) + andOthers;
      }
      return response;
    },
    noItems() {
      return !(this.items && this.items.length);
    },
  },
  watch: {
    selected(items) {
      this.selectedItems = items;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

::v-deep {
  .v-data-table tbody td:first-child {
    width: 20px;
  }
  .v-data-table tbody td, .v-simple-checkbox .v-icon {
    height: 35px;
  }
}
</style>
